import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import SimplePeer from 'simple-peer';
import { Chess } from 'chess.js';
import { Chessboard } from 'react-chessboard';
import './App.css';

// Update the WebSocket URL to your Render backend
const socket = io('https://chessica.onrender.com'); // Replace with your Render server address

const App = () => {
  const [onlineCount, setOnlineCount] = useState(0);
  const [inCall, setInCall] = useState(false);
  const [searching, setSearching] = useState(false);
  const [chess, setChess] = useState(new Chess());
  const [gameStarted, setGameStarted] = useState(false);
  const [color, setColor] = useState('white'); // Track the player's color
  const myVideo = useRef();
  const partnerVideo = useRef();
  const peerRef = useRef();

  useEffect(() => {
    // Fetch initial count from your Render backend
    axios.get('https://chessica.onrender.com/count') // Replace with your Render server address
      .then(response => setOnlineCount(response.data.count))
      .catch(error => console.error(error));

    // Listen for updates
    socket.on('updateCount', count => setOnlineCount(count));

    // Cleanup on component unmount
    return () => {
      socket.off('updateCount');
    };
  }, []);

  const handlePlay = () => {
    setSearching(true);
    socket.emit('play');
  };

  useEffect(() => {
    socket.on('paired', async ({ initiator }) => {
      setInCall(true);
      setSearching(false);
      setGameStarted(true);
      setColor(initiator ? 'white' : 'black'); // Assign colors
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      myVideo.current.srcObject = stream;

      peerRef.current = new SimplePeer({
        initiator,
        trickle: false,
        stream
      });

      peerRef.current.on('signal', data => {
        socket.emit('signal', data);
      });

      peerRef.current.on('stream', stream => {
        partnerVideo.current.srcObject = stream;
      });

      socket.on('signal', data => {
        peerRef.current.signal(data);
      });
    });

    socket.on('move', (move) => {
      const game = new Chess(chess.fen());
      game.move(move);
      setChess(game);
    });

    return () => {
      socket.off('paired');
      socket.off('signal');
      socket.off('move');
    };
  }, [chess]);

  const handleMove = (sourceSquare, targetSquare) => {
    const game = new Chess(chess.fen());
    const move = { from: sourceSquare, to: targetSquare, promotion: 'q' }; // always promote to a queen for simplicity

    // Check if it's the player's turn and if the move is valid
    if ((game.turn() === 'w' && color === 'white') || (game.turn() === 'b' && color === 'black')) {
      const validMoves = game.moves({ verbose: true });
      const isValidMove = validMoves.some(validMove => 
        validMove.from === sourceSquare && validMove.to === targetSquare
      );

      if (isValidMove) {
        game.move(move);
        setChess(game);
        socket.emit('move', move);
      } else {
        console.error('Invalid move:', move);
      }
    } else {
      console.warn('Not your turn:', move);
    }
  };

  return (
    <div className="app">
      {/* <div className='logo'>
        <a href="/">
          <img 
            src="/CR.png" 
            alt="Logo" 
            width={150} 
            style={{
              position: 'absolute',
              top: 0,
              left: '10%',
              transform: 'translateX(-50%)',
              zIndex: 1000 // Ensure it's on top of other elements
            }}
          />
        </a>
      </div> */}
      <h2>Video Call and Play Chess with Strangers around the World</h2>
      <h1>Total People Online: {onlineCount}</h1>
      <br/>
      {!inCall && (
        <div>
          <button onClick={handlePlay} disabled={searching}>
            {searching ? 'Searching for an opponent...' : 'Play'}
          </button>
        </div>
      )}
      {inCall && (
        <div className="game-container">
          <Chessboard
            position={chess.fen()}
            onPieceDrop={(sourceSquare, targetSquare) => handleMove(sourceSquare, targetSquare)}
            boardOrientation={color} // Set the board orientation based on the player's color
            boardWidth={Math.min(window.innerWidth * 0.4, 600)} // Adjust the board width responsively
          />
          <div className="videos">
            <video ref={myVideo} autoPlay muted className="video" />
            <video ref={partnerVideo} autoPlay className="video" />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
